// THIS FILE IS NOT IN USE


global.toastr = require("toastr")
global.bootbox = require("bootbox")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require ("jquery")

// https://stackoverflow.com/questions/58371711/bootstrap-hide-modal-not-working-in-rails-6-using-ajax
// import jquery from 'jquery';
// window.$ = window.jquery = jquery;

import "bootstrap";

var priceplan = 'monthly';

var spinner_html = "<span class='spinner-border spinner-border-sm mr-1' role='status' aria-hidden='true'></span>";
document.addEventListener('turbolinks:load', () => {

	$('#registration-submit-button').click(function(e) {
		var pass = $('#user_password').val();
		var passConfirm = $('#user_password_confirmation').val();
		if (pass == passConfirm) {
			//do nothing
		} else {
			e.preventDefault();
			toastr.error('Passwords must match!')
		}
	});

	$('.pricebtn').click(function(e) {
	  var selectedPlanStr = $(this).attr('id');
	  var selectedPlan = selectedPlanStr.replace('label-', '');

	  if (selectedPlan == 'monthly') {
	  	$('#label-monthly').addClass('active');
	  	$('#label-monthly').addClass('focus');
	  	$('#label-annual').removeClass('active');
	  	$('#label-annual').removeClass('focus');
	  	$('.monthly').show();
	  	$('.annual').hide();
	  } else {
	  	$('#label-monthly').removeClass('active');
	  	$('#label-monthly').removeClass('focus');
	  	$('#label-annual').addClass('active');
	  	$('#label-annual').addClass('focus');
	  	$('.monthly').hide();
	  	$('.annual').show();	  	
	  }
	});

	$('#signup-form').submit(function(e) {
		var thename = $('#input-name').val();
		var theemail = $('#input-name').val();

		var thebutton = $(this);
		thebutton.prop('disabled', true);
		var current_button_text = thebutton.html();
		thebutton.html(spinner_html + current_button_text);

		var name = $('#input-name').val();
		console.log($('#input-name').val())
		var post_data = {
			'submission': {
				'name': thename,
				'email': theemail,
			}
		};

		$.ajax({
			type: "POST",
			url: '/submissions',
			headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
			dataType: 'json',
			data: post_data,
			error: function(jqXHR, textStatus, errorThrown) {
				console.log(jqXHR);
				console.log(textStatus);
				toastr.error('Oops. An error occured while submitting your details.')
				thebutton.prop('disabled', false);
				thebutton.html(current_button_text);
			},
			success: function(data, textStatus, jqXHR) {
				var successful_html = "<h5 class='text-center'>✅ Thanks! You'll shortly receive an email from us!</h5>";
				$('#get-started .container').html(successful_html);
			}
		});
		e.preventDefault();
	});

}); //end document.ready